import PropTypes from "prop-types"
import React from "react"

// function isNotFr(window) {
//     if (typeof window !== 'undefined') {
//         if (window.location.pathname.includes("/en/") === true) return true
//         if (window.location.pathname.includes("/de/") === true) return true
//     }
//     return false
// }

function getLang(window) {
    if (typeof window !== 'undefined') {
        if (window.location.pathname.includes("/en/") === true) return 'en'
        if (window.location.pathname.includes("/de/") === true) return 'de'
        if (window.location.pathname.includes("/it/") === true) return 'it'
        if (window.location.pathname.includes("/es/") === true) return 'es'
    }
    return 'fr'
}

function changeLang(lg) {
    if (["en", "de", "it", "es"].indexOf(lg) !== -1) window.location = '/' + lg + '/'
    else window.location = '/'
}

const Header = ({ siteTitle, siteHeader, siteImageTitle, siteHeaderMobile }) => (
    <header style={{ position: "relative", borderBottomStyle: "solid", borderBottomWidth: 0, height: 100, maxHeight: 100, minHeight: 100, paddingTop: 30 }}>
        <div onClick={() => getLang(window) === 'fr' ? window.location = "/" : window.location = "/" + getLang(window) + "/"} style={{ cursor: 'pointer', position: "relative", height: 46, width: 320, margin: "auto" }} className="btn-home-hide-little">
            {/* <img alt='header_sommelier_blog' src={siteHeader} style={{ height: "100%", width: "auto" }} /> */}
            <div style={{ textAlign: "center", width: "100%", fontFamily: "SB", fontSize: 18, top: 10, position: "absolute", left: "50%", transform: "translateX(-50%)" }}>
                SOMMELIER DU PARFUM <span style={{ fontFamily: "SBLight", fontStyle: "italic" }}>Blog</span>
            </div>
        </div>
        <div style={{ position: "absolute", left: "7%", top: "51%", transform: 'translateY(-50%)', zIndex: 1 }} className="btn-home-hide-little">
            <img alt="" src={"/../images/arrow.svg"} style={{ transform: "rotate(90deg)", position: "absolute", right: 4, top: 9, zIndex: -1 }} />
            <select className="select-footer-lang dsselect" value={typeof (window) !== "undefined" ? getLang(window) : "fr "}
                onChange={(e) => changeLang(e.target.value)}>
                {["fr", "en", "de", "it", "es"].map(lg =>
                    <option key={lg} value={lg}>{lg.toUpperCase()}</option>
                )}
            </select>
        </div>
        <div style={{ position: "absolute", right: "7%", top: "50%", transform: 'translateY(-50%)', zIndex: 1 }} className="btn-home-hide-little">
            <a rel="noopener noreferrer" href="https://sommelierduparfum.com" target='_blank' className="" style={{
                fontFamily: 'FuturaBook', textDecoration: "none", color: "#eee", padding: "14px 28px", textTransform: "uppercase",
                fontSize: 14, letterSpacing: "0.02em", display: 'inline-flex', backgroundColor: "#000"
            }}>
                {typeof (window) !== "undefined" && getLang(window) === "fr" ? "Découvrir mon parfum"
                    : typeof (window) !== "undefined" && getLang(window) === "de" ? "Entdecken Sie mein Parfüm"
                        : typeof (window) !== "undefined" && getLang(window) === "it" ? "Scoprite il mio profumo"
                            : typeof (window) !== "undefined" && getLang(window) === "es" ? "Encontrar mi fragancia"
                                : "Discover my perfume"}
            </a>
        </div>
        <div onClick={() => window.location = "/"} style={{ cursor: 'pointer', position: "relative", height: 46, width: 250 }} className="btn-home-hide-big margin-btn-home-hide-big">
            {/* <img alt='header_sommelier_blog_little' src={siteHeaderMobile} style={{ height: "100%", width: "auto" }} /> */}
            <div style={{ textAlign: "left", width: "100%", fontFamily: "SB", fontSize: 15, top: 9, position: "absolute", left: 0 }}>
                SOMMELIER DU PARFUM <span style={{ fontFamily: "SBLight", fontStyle: "italic" }}>Blog</span>
            </div>
        </div>
        <div style={{ position: "absolute", right: "0%", top: "36%", transform: 'translateX(-50%)', zIndex: 1 }} className="btn-home-hide-big">
            <img alt="" src={"/../images/arrow.svg"} style={{ transform: "rotate(90deg)", position: "absolute", right: 4, top: 9, zIndex: -1 }} />
            <select className="select-footer-lang dsselect" value={typeof (window) !== "undefined" ? getLang(window) : "fr"} onChange={(e) => changeLang(e.target.value)}>
                {["fr", "en", "de", "it", "es"].map(lg =>
                    <option key={lg} value={lg}>{lg.toUpperCase()}</option>
                )}
            </select>
        </div>
        {/* <div style={{ position: "absolute", right: "8%", top: "67%", transform: 'translateY(-50%)', zIndex: 1 }} className="btn-home-hide-big">
            <a rel="noopener noreferrer" href="https://sommelierduparfum.com" target='_blank' className="" style={{
                fontFamily: 'FuturaBook', textDecoration: "none", color: "#000",
                fontSize: 16, letterSpacing: "0.02em", display: 'inline-flex'
            }}>
                {isNotFr(window) ? "To the website" : "Vers le site"}
                <img src={isNotFr(window) ? "/../../images/arrow.svg" : "/../images/arrow.svg"} alt="arrow_header" style={{ height: 12, position: 'absolute', right: -13, top: 9 }} />
            </a>
        </div> */}
    </header>
)

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}

export default Header
