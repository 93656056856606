/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql, Link } from "gatsby"

import Header from "./header"
import Mailchimp from "./Mailchimp"
import "./layout.css"
import "./Fonts.css"

function isNotFr(window) {
    if (typeof window !== 'undefined') {
        if (window.location.pathname.includes("/en/") === true) return true
        if (window.location.pathname.includes("/de/") === true) return true
        if (window.location.pathname.includes("/it/") === true) return true
        if (window.location.pathname.includes("/es/") === true) return true
    }
    return false
}

function getLang(window) {
    if (typeof window !== 'undefined') {
        if (window.location.pathname.includes("/en/") === true) return 'en'
        if (window.location.pathname.includes("/de/") === true) return 'de'
        if (window.location.pathname.includes("/it/") === true) return 'it'
        if (window.location.pathname.includes("/es/") === true) return 'es'
    }
    return 'fr'
}

var array_lang_available = ['en', 'fr', "de", "it", "es"]

var lag = {
    "en": "EN",
    "fr": "FR",
    "de": "DE",
    "it": "IT",
    "es": "ES"
}

var sdp_multi = {
    "en": {
        'footer': {
            "title_product": "Find my perfume",
            "sub_title_reco": "Recommandations",
            "sub_title_explore": "Collections",
            "sub_title_all": "All Articles",
            "title_sharing": "Follow us",
            "title_about": "About",
            "sub_title_story": "Notre histoire",
            "sub_title_contact": "Contact",
            "sub_title_jobs": "Jobs",
            "title_news": "News",
            "privacy": "Privacy Policy",
            "ship": "Shipping and Returns",
            "cgv": "Terms and Conditions of Sale",
            "sub_title_news": "Get expert advice and unmistakable launch news before anyone else.",
            "title_bottom": "The right fragrance is just a click away",
            "btn_foot": "Find my perfume"
        }
    },
    "fr": {
        'footer': {
            "title_product": "Trouver son parfum",
            "sub_title_reco": "Recommandations",
            "sub_title_explore": "Collections",
            "sub_title_all": "Tous les Articles",
            "title_sharing": "Suivez-nous",
            "title_about": "A propos",
            "sub_title_story": "Notre histoire",
            "sub_title_contact": "Contact",
            "sub_title_jobs": "Jobs",
            "title_news": "Actualités",
            "privacy": "Politique de confidentialité",
            "ship": "Livraisons et Retours",
            "cgv": "Conditions Générales de Vente",
            "sub_title_news": "Recevez les conseils experts et les nouvelles de lancements inmanquables avant tout le monde.",
            "title_bottom": "Le parfum idéal est à votre portée.",
            "btn_foot": "Trouver mon Parfum"
        }
    },
    "de": {
        'footer': {
            "title_product": "Mein Parfüm finden",
            "sub_title_reco": "Empfehlungen",
            "sub_title_explore": "Sammlungen",
            "sub_title_all": "Alle Artikel",
            "title_sharing": "Folgen Sie uns",
            "title_about": "Über",
            "sub_title_story": "Unsere Geschichte",
            "sub_title_contact": "Kontakt",
            "sub_title_jobs": "Jobs",
            "title_news": "Nachrichten",
            "privacy": "Datenschutzrichtlinie",
            "ship": "Lieferungen und Rücksendungen",
            "cgv": "Allgemeine Verkaufsbedingungen",
            "sub_title_news": "Holen Sie sich fachkundigen Rat und unmissverständliche Neuigkeiten zur Markteinführung vor allen anderen.",
            "title_bottom": "Der richtige Duft ist nur einen Klick entfernt",
            "btn_foot": "Mein Parfüm finden"
        }
    },
    "it": {
        'footer': {
            "title_product": "Trova il mio profumo",
            "sub_title_reco": "Raccomandazioni",
            "sub_title_explore": "Collezioni",
            "sub_title_all": "Tutti gli Articoli",
            "title_sharing": "Seguici",
            "title_about": "Chi siamo",
            "sub_title_story": "La nostra storia",
            "sub_title_contact": "Contatti",
            "sub_title_jobs": "Lavori",
            "title_news": "Notizie",
            "privacy": "Informativa sulla privacy",
            "ship": "Consegne e resi",
            "cgv": "Condizioni generali di vendita",
            "sub_title_news": "Ricevi i consigli degli esperti e le notizie inequivocabili sul lancio prima di chiunque altro",
            "title_bottom": "Il profumo giusto è a portata di clic",
            "btn_foot": "Trova il mio profumo"
        }
    },
    "es": {
        'footer': {
            "title_product": "Encontrar su perfume",
            "sub_title_reco": "Recomendaciones",
            "sub_title_explore": "Colecciones",
            "sub_title_all": "Todos los artículos",
            "title_sharing": "Síguenos en",
            "title_about": "A propos",
            "sub_title_story": "Nuestra historia",
            "sub_title_contact": "Contacto",
            "sub_title_jobs": "Jobs",
            "title_news": "Noticias",
            "privacy": "Política de privacidad",
            "ship": "Entregas y devoluciones",
            "cgv": "Condiciones generales de venta",
            "sub_title_news": "Reciba antes que nadie consejos de expertos y noticias sobre lanzamientos ineludibles.",
            "title_bottom": "La fragancia perfecta está a su alcance.",
            "btn_foot": "Encontrar mi fragancia"
        }
    }
}

var listGrid =
    typeof (window) !== "undefined" && isNotFr(window) ?
        [
            "/../../images/land/b1.jpg",
            "/../../images/land/b2.jpg",
            "/../../images/land/b3.jpg",
            "/../../images/land/b4.jpg",
            "/../../images/land/b5.jpg"
        ]
        :
        [
            "/../images/land/b1.jpg",
            "/../images/land/b2.jpg",
            "/../images/land/b3.jpg",
            "/../images/land/b4.jpg",
            "/../images/land/b5.jpg"
        ]


const Layout = ({ children }) => (
    <StaticQuery
        query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
        render={data => (
            <>
                <Header
                    siteTitle={data.site.siteMetadata.title}
                    siteHeader={typeof (window) !== "undefined" && isNotFr(window) ? "/../../images/header.png" : "/../images/header.png"}
                    siteHeaderMobile={typeof (window) !== "undefined" && isNotFr(window) ? "/../../images/header_little.png" : "/../images/header_little.png"}
                    siteImageTitle={typeof (window) !== "undefined" && isNotFr(window) ? "/../../images/image_title.png" : "/../images/image_title.png"} />
                <div className="padd-home"
                    style={{
                        margin: `0 auto`,
                        maxWidth: 1200,
                        // padding: typeof (window) !== "undefined" ? window.innerWidth ? window.innerWidth < 900 ? "25px 0px 25px 0px" : "25px 20px 25px 20px" : "25px 20px 25px 20px" : "25px 20px 25px 20px",
                        minHeight: typeof (window) !== "undefined" ? window.innerHeight ? window.innerHeight - 175 : 500 : 500
                    }}
                >
                    <main>{children}</main>
                </div>
                <div style={{ width: "100%", backgroundColor: "#f9f7f4", height: 500, position: 'relative', overflow: "hidden" }}>
                    {listGrid.map((bb, bbI) =>
                        <div key={"bg-bot-" + bbI} style={{ backgroundImage: "url(" + bb + ')' }} className={"transition-700 bg-image-bottom-" + bbI} ></div>
                    )}
                    <div className="center-absolute" style={{ width: "70%" }}>
                        <div className="title-bottom-white" style={{ color: "#000", textAlign: "center", fontFamily: "SBLight", fontSize: 40, lineHeight: "60px" }}>
                            {typeof (window) !== "undefined" ? sdp_multi[getLang(window)]['footer']['title_bottom'] : ""}
                        </div>
                        <a target="_blank" rel="noopener noreferrer" className="no-underline-hover" href={typeof (window) !== "undefined" ? 'https://sommelierduparfum.com/' + getLang(window) + '/recos/' : 'https://sommelierduparfum.com/fr/recos/'}>
                            <div className="btn-footer-reco-e transition-300" style={{}}>
                                {typeof (window) !== "undefined" ? sdp_multi[getLang(window)]['footer']['btn_foot'] : ""}
                            </div>
                        </a>
                    </div>
                </div>
                <footer>
                    <div>
                        <div style={{ backgroundColor: "#161616", padding: "60px 0px 75px 0px" }}>
                            <div className="container">
                                <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center">
                                    <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center no-padding">
                                        <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 row text-center no-padding" style={{ marginLeft: 0 }}>
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 text-center">
                                                <a href={typeof (window) !== "undefined" ? 'https://sommelierduparfum.com/' + getLang(window) + '/recos/' : 'https://sommelierduparfum.com/fr/recos/'} className="no-underline-hover">
                                                    <div className="no-underline-hover" >
                                                        <div className="link-one-footer-big">
                                                            {typeof (window) !== "undefined" ? sdp_multi[getLang(window)]['footer']['sub_title_reco'] : ""}
                                                        </div>
                                                    </div>
                                                </a>
                                                <a href={typeof (window) !== "undefined" ? 'https://sommelierduparfum.com/' + getLang(window) + '/explore/' : 'https://sommelierduparfum.com/fr/explore/'} className="no-underline-hover">
                                                    <div className="no-underline-hover" >
                                                        <div className="link-one-footer-big">
                                                            {typeof (window) !== "undefined" ? sdp_multi[getLang(window)]['footer']['sub_title_explore'] : "Explore"}
                                                        </div>
                                                    </div>
                                                </a>
                                                <Link to={typeof (window) !== "undefined" && getLang(window) === 'en' ? "/en/all-articles/"
                                                    : typeof (window) !== "undefined" && getLang(window) === 'de' ? "/de/alle-artikel/"
                                                        : typeof (window) !== "undefined" && getLang(window) === 'it' ? "/it/tutti-gli-articoli/"
                                                            : typeof (window) !== "undefined" && getLang(window) === 'es' ? "/es/todos-los-articulos/"
                                                                : "/tous-les-articles/"}
                                                    style={{ textDecoration: "none" }} className="link" >
                                                    <div className="link-one-footer-big">
                                                        {typeof (window) !== "undefined" ? sdp_multi[getLang(window)]['footer']['sub_title_all'] : "Tous les articles"}
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="offset-lg-0 offset-md-0 col-xl-3 offset-xl-2 col-lg-4 col-md-4 col-sm-12 col-12 text-center row" style={{}}>
                                                <div className="col-lg-12 col-md-12 col-sm-6 col-6 no-paddings">
                                                    <div className="title-footer margtop-mobileI">
                                                        {typeof (window) !== "undefined" ? sdp_multi[getLang(window)]['footer']['title_sharing'] : ""}
                                                    </div>
                                                    <a className="no-underline-hover" href="https://www.instagram.com/sommelierduparfum/" target='_blank' rel="noopener noreferrer" >
                                                        <div className="link-one-footer">
                                                            Instagram
                                                        </div>
                                                    </a>
                                                    <a className="no-underline-hover" href="https://www.facebook.com/sommelierdp/" target='_blank' rel="noopener noreferrer" >
                                                        <div className="link-one-footer">
                                                            Facebook
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-sm-6 col-6 no-paddings">
                                                    <div className="title-footer margtop-mobile">
                                                        {typeof (window) !== "undefined" ? sdp_multi[getLang(window)]['footer']['title_about'] : sdp_multi['fr']['footer']['title_about']}
                                                    </div>
                                                    <Link to={typeof (window) !== "undefined" && getLang(window) === 'en' ? "/en/all-articles/"
                                                        : typeof (window) !== "undefined" && getLang(window) === 'de' ? "/de/alle-artikel/"
                                                            : typeof (window) !== "undefined" && getLang(window) === 'it' ? "/it/tutti-gli-articoli/"
                                                                : typeof (window) !== "undefined" && getLang(window) === 'es' ? "/es/todos-los-articulos/"
                                                                    : "/tous-les-articles/"}
                                                        style={{ textDecoration: "none" }} className="link" >
                                                        <div className="link-one-footer">
                                                            {typeof (window) !== "undefined" ? sdp_multi[getLang(window)]['footer']['sub_title_all'] : ""}
                                                        </div>
                                                    </Link>
                                                    {typeof (window) !== "undefined" && getLang(window) === 'fr' ?
                                                        <Link to={"/"} style={{ textDecoration: "none" }} className="link" >
                                                            <div className="link-one-footer">
                                                                Blog français
                                                            </div>
                                                        </Link>
                                                        :
                                                        typeof (window) !== "undefined" && getLang(window) === 'en' ?
                                                            <Link to={"/en/"} style={{ textDecoration: "none" }} className="link" >
                                                                <div className="link-one-footer">
                                                                    English blog
                                                                </div>
                                                            </Link>
                                                            :
                                                            typeof (window) !== "undefined" && getLang(window) === 'it' ?
                                                                <Link to={"/it/"} style={{ textDecoration: "none" }} className="link" >
                                                                    <div className="link-one-footer">
                                                                        Italian blog
                                                                    </div>
                                                                </Link>
                                                                :
                                                                typeof (window) !== "undefined" && getLang(window) === 'es' ?
                                                                    <Link to={"/es/"} style={{ textDecoration: "none" }} className="link" >
                                                                        <div className="link-one-footer">
                                                                            Spanish blog
                                                                        </div>
                                                                    </Link>
                                                                    :
                                                                    <Link to={"/de/"} style={{ textDecoration: "none" }} className="link" >
                                                                        <div className="link-one-footer">
                                                                            Deutscher Blog
                                                                        </div>
                                                                    </Link>
                                                    }
                                                    <a className="no-underline-hover" href="https://drive.google.com/drive/folders/1_PmRZ572BOhDgAEOuJYOuJ-uytkVN3Xg?usp=sharing" target='_blank' rel="noopener noreferrer" >
                                                        <div className="link-one-footer">
                                                            Kit media
                                                        </div>
                                                    </a>
                                                    <a className="no-underline-hover" href="mailto:hello@sommelierduparfum.com" target='_blank' rel="noopener noreferrer" >
                                                        <div className="link-one-footer">
                                                            Contact
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col-12 col-xl-3 col-lg-4 col-md-4 col-sm-12 text-center margin-t-mobile">
                                                <div className="title-footer">
                                                    {typeof (window) !== "undefined" ? sdp_multi[getLang(window)]['footer']['title_news'] : sdp_multi["fr"]['footer']['title_news']}
                                                </div>
                                                <div className="link-one-footer" style={{ textTransform: "none", opacity: 1 }}>
                                                    {typeof (window) !== "undefined" ? sdp_multi[getLang(window)]['footer']['sub_title_news'] : sdp_multi["fr"]['footer']['sub_title_news']}
                                                </div>
                                                <Mailchimp />
                                                <div className="title-footer" style={{ marginTop: 60 }}>
                                                    Get the app
                                                </div>
                                                <div style={{ display: "inline-flex", marginTop: 5, width: "100%" }}>
                                                    <div className="link-one-footer" style={{ cursor: 'pointer', width: "calc(50% - 5px)", maxWidth: 150, position: "relative" }}>
                                                        <a className="no-underline-hover" href="http://bit.ly/SDP-AppStore" target="_blank" rel="noopener noreferrer">
                                                            <div style={{ display: "inline-flex", position: "relative" }}>
                                                                <img alt="" src="./../images/ios_.png" className="" style={{ height: 40, marginLeft: 10 }} />
                                                                <div style={{ paddingTop: 10, paddingLeft: 5, color: "#f9f7f4" }}>IOS</div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div className="link-one-footer" style={{ cursor: 'pointer', width: "calc(50% - 5px)", maxWidth: 150, position: "relative" }}>
                                                        <a className="no-underline-hover" href="https://play.google.com/store/apps/details?id=com.sommelierduparfum.app&referrer=utm_source%3DWebsite%26utm_medium%3Dbutton%26utm_content%3DLaunch%26utm_campaign%3DSDPapp%26anid%3Dadmob" target="_blank" rel="noopener noreferrer">
                                                            <div style={{ display: "inline-flex", position: "relative" }}>
                                                                <img alt="" src="./../images/android_.png" className="" style={{ height: 40, marginLeft: 10 }} />
                                                                <div style={{ paddingTop: 10, paddingLeft: 5, color: "#f9f7f4" }}>ANDROID</div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ backgroundColor: "#161616", padding: "10px 0 50px 0", position: "relative" }}>
                            <div className="container">
                                <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center no-padding" style={{ position: "relative" }}>
                                    <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 row text-center no-padding-mobile" style={{ marginLeft: 0, paddingTop: 20, position: "relative" }}>
                                        <div style={{ backgroundColor: "rgb(249, 247, 244, 0.2)", top: 0, width: "calc(100% - 60px)", left: 30, height: 1, position: "absolute" }}></div>
                                        {typeof (window) !== "undefined" && window.innerWidth && window.innerWidth <= 768 ?
                                            <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 row" style={{ marginLeft: 0 }}>
                                                <div className="col-5 col-xl-5 col-lg-5 col-md-5 col-sm-5 text-center" style={{ marginLeft: 0, display: "inline-flex" }}>
                                                    {array_lang_available.map((cur, curI) =>
                                                        <Link key={'lang_page_' + curI} to={cur === "fr" ? "/" : "/" + cur + "/"} style={{ textDecoration: "none" }} className="no-underline-hover">
                                                            <div className={typeof (window) !== "undefined" && getLang(window) === cur ? "currency-bottom-select transition-300" : "currency-bottom transition-300"}>
                                                                {lag[cur]}
                                                            </div>
                                                        </Link>
                                                    )}
                                                </div>
                                            </div>
                                            :
                                            <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 row no-padding" style={{ marginLeft: 0 }}>
                                                <div className="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 row text-center" style={{ marginLeft: 0 }}>
                                                    {array_lang_available.map((cur, curI) =>
                                                        <Link key={'lang_page_' + curI} to={cur === "fr" ? "/" : "/" + cur + "/"} style={{ textDecoration: "none" }} className="no-underline-hover">
                                                            <div className={typeof (window) !== "undefined" && getLang(window) === cur ? "currency-bottom-select transition-300" : "currency-bottom transition-300"}>
                                                                {lag[cur]}
                                                            </div>
                                                        </Link>
                                                    )}
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                </footer>
            </>
        )}
    />
)

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
