import React from 'react';
import Helmet from 'react-helmet'

const keywordsgeneric = ['parfums', "parfum", "fragrance", "sommelier", "Sommelier du Parfum", "sommelierduparfum", "trouver", "find", "recommandations", "cadeau", "IA", "application", 'intelligence artificielle']

function MetaTags(props) {

    var timePost = []
    if (props.dateCreation) {
        timePost.push({
            name: 'content_origin',
            content: props.dateCreation.substring(0, 10),
        })
    }
    if (props.dateCreation) {
        timePost.push({
            name: 'content_updated',
            content: props.dateUpdate.substring(0, 10),
        })
    }
    return (
        <Helmet
            title={props.title}
            meta={[
                { name: 'title', content: props.title },

                { name: 'google-site-verification', content: 'vdARKrVjBXEUTWJg7otwIeAPw4c3F4ZwPAABI6eMdC8' },

                { name: 'description', content: props.description },
                {
                    property: 'og:title',
                    content: props.title,
                },
                {
                    property: 'og:url',
                    content: props.pathname,
                },

                {
                    property: 'og:image',
                    content: props.thumbnail,
                },
                {
                    property: 'og:image:secure_url',
                    content: props.thumbnail,
                },

                {
                    property: 'og:description',
                    content: props.description,
                },

                {
                    property: 'og:image:width',
                    content: '1200',
                },

                {
                    property: 'og:image:height',
                    content: '630',
                },
                {
                    property: 'og:locale',
                    content: 'en',
                },
                { name: 'twitter:card', content: 'https://s3.eu-central-1.amazonaws.com/fragrancebutler.me/share_image/CTA_share/share_image_sdp.png' },

                { name: 'twitter:title', content: props.title },

                {
                    name: 'twitter:description',
                    content: props.description,
                },
                {
                    name: 'twitter:image',
                    content: props.thumbnail,
                },
                { property: 'og:type', content: 'website' },
                { name: 'robots', content: 'index, follow' },

                { property: 'og:site_name', content: 'Blog Sommelier du Parfum' }
            ].concat(
                keywordsgeneric.length > 0
                    ? {
                        name: `keywords`,
                        content: keywordsgeneric.join(`, `),
                    }
                    : []
            ).concat(timePost)
            }
        >
            <html lang="fr" />
            <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/latest/css/bootstrap.min.css"></link>
        </Helmet>
    )
}

export default MetaTags;
