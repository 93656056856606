import React, { Component } from "react"
import 'moment/locale/fr';
const axios = require('axios');

export class Mailchimp extends Component {

    constructor() {
        super()
        this.state = {
            subscribed_to_mailchimpe_loader: false,
            user_mail: "",
            functionevent: function (e) {
                this.codeKeyboard(e)
            }.bind(this),
        }
    }

    componentDidMount() {
        document.addEventListener("keydown", this.state.functionevent, false);
    }
    componentWillUnmount() {
        document.removeEventListener("keydown", this.state.functionevent, false);
    }

    codeKeyboard(e) {
        if (this.inputEmail === document.activeElement && e.code === 'Enter') this.addMailChimp()
    }

    validateEmail() {
        var email = this.state.user_mail
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        //return re.test(String(email).toLowerCase());
        if (re.test(String(email).toLowerCase())) return false
        else return true
    }

    addMailChimp() {
        if (this.validateEmail()) {
            return
        }
        else {
            this.setState({ subscribed_to_mailchimpe_loader: true })
            var user_mail_sub = {
                'email_address': this.state.user_mail,
                'status': 'subscribed',
                'LANGUAGE': 'fr',
                "from_blog": true
            }
            axios.post('https://cors-anywhere.herokuapp.com/https://app.api.sommelierduparfum.me/mail_user_subscribed', user_mail_sub, { headers: { 'Accept': 'application/json', 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json', } }).then((res => {
                this.setState({ subscribed_to_mailchimpe: true, subscribed_to_mailchimpe_loader: false })
                setTimeout(
                    function () {
                        this.setState({ subscribed_to_mailchimpe: false, user_mail: "" })
                    }.bind(this), 1500
                )
            }))
                .catch((resp => {
                    this.setState({ subscribed_to_mailchimpe: false, user_mail: "" })
                    alert('An error occured, sorry for that')
                }))
        }
    }

    render() {
        return (

            <div style={{ display: 'inline-flex', width: "100%" }}>
                <input value={this.state.user_mail} onChange={(e) => this.setState({ user_mail: e.target.value })}
                    className="news-input-footer" placeholder='Email' ref={inputEmail => this.inputEmail = inputEmail}
                />
                <div className='btn-valid-news' onClick={() => this.addMailChimp()}>
                    <img alt='img-mailchimp-footer' src={this.state.subscribed_to_mailchimpe ? './../images/valide.svg' : './../images/news_send.svg'} className="center-absolute"
                        style={{ height: this.state.subscribed_to_mailchimpe ? 12 : 10 }} />
                </div>
            </div>
        )
    }
}

export default Mailchimp
